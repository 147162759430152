<template>
  <section class="bg-grey-light font-sans">
    <div class="container w-full md:max-w-3xl mx-auto py-20">
      <div
        class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal"
        style="font-family: Georgia, serif"
      >
        <div class="font-sans">
          <h1
            class="font-bold font-sans break-normal text-black-1 pt-6 pb-2 text-3xl md:text-4xl"
          >
            TERMS OF USE
          </h1>
        </div>

        <p class="py-6 leading-1.5 tracking-normal">
          Website Terms of Use manage the use of a website by visitors. These
          are distinct from terms and conditions of business which are concerned
          with the e-commerce aspects of selling goods or services online,
          rather than the way in which a website is used. Any businesses with an
          online presence must include certain details in order to abide by the
          Electronic Commerce Regulations. Website terms and conditions are the
          best place to include such information.
        </p>

        <ol>
          <li class="py-3 leading-1.5 tracking-normal">
            Ensuring that your users understand the limitations of how they can
            use any website content, including text, images, videos and music,
            helps to secure your intellectual property.
          </li>
          <li class="py-3 leading-1.5 tracking-normal">
            If you don't have a clue on how to make terms of use for your
            website, we can help you out. Just fill in the spaces below and we
            will send you an email with your very own terms of use for your
            website.
          </li>
          <li class="py-3 leading-1.5 tracking-normal">
            The accuracy of the generated document on this website is not
            legally binding. Use at your own risk.
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermsOfUse",
};
</script>
