<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="isOpen ? 'block' : 'hidden'"
      class="
        fixed
        z-20
        inset-0
        bg-black-2
        opacity-50
        transition-opacity
        lg:hidden
      "
      @click="isOpen = false"
    ></div>
    <!-- End Backdrop -->

    <div
      :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="
        flex flex-col
        justify-between
        fixed
        z-30
        inset-y-0
        left-0
        w-64
        transition
        duration-300
        transform
        bg-white
        dark:bg-dark-bgSecondary
        overflow-y-auto
        lg:translate-x-0 lg:static lg:inset-0
      "
    >
      <div>
        <div
          class="
            flex
            h-20
            items-center
            border-b border-grey-light
            dark:border-dark-textSecondary
            pl-6
          "
        >
          <img
            src="@/assets/images/logo.png"
            alt="Your avatar"
            class="w-28 object-cover"
          />
        </div>
        <nav class="mt-7">
          <router-link
            to="/"
            :class="[
              $route.path.includes('home') ? activeClass : inactiveClass,
            ]"
            class="nav-link flex items-center duration-200 mt-4 py-2 px-6"
          >
            <HomeIcon
              class="
                h-8
                w-8
                fill-current
                text-grey-light
                dark:text-dark-textSecondary
              "
            />

            <span class="mx-4">Home</span>
          </router-link>

          <router-link
            to="/events"
            :class="[
              $route.path.includes('events') ? activeClass : inactiveClass,
            ]"
            class="nav-link flex items-center duration-200 mt-4 py-2 px-6"
          >
            <EventIcon
              class="
                h-8
                w-8
                fill-current
                text-grey-light
                dark:text-dark-textSecondary
              "
            />

            <span class="mx-4">Events</span>
          </router-link>

          <router-link
            v-if="isAdmin"
            to="/users"
            :class="[
              $route.path.includes('users') ? activeClass : inactiveClass,
            ]"
            class="nav-link flex items-center duration-200 mt-4 py-2 px-6"
          >
            <UsersIcon
              class="
                h-8
                w-8
                fill-current
                text-grey-light
                dark:text-dark-textSecondary
              "
            />

            <span class="mx-4">Users</span>
          </router-link>

          <router-link
            to="/reset-password"
            :class="`nav-link logout flex items-center duration-200 mt-4 py-2 px-6 ${inactiveClass}`"
          >
            <PasswordReset class="h-8 w-8 stroke-grey-light dark:stroke-dark" />
            <span class="mx-4">Reset Password</span>
          </router-link>

          <router-link
            to="/"
            :class="`nav-link logout flex items-center duration-200 mt-4 py-2 px-6 ${inactiveClass}`"
            @click="handleLogout()"
          >
            <LogoutIcon class="h-8 w-8 stroke-grey-light dark:stroke-dark" />

            <span class="mx-4">Logout</span>
          </router-link>
        </nav>
      </div>

      <div>
        <div class="border-b border-grey-light py-3 px-6">
          <h4
            :class="[
              $route.path.includes('privacy')
                ? 'text-magenta'
                : 'text-grey-mid dark:text-dark-textPrimary',
            ]"
            class="font-medium cursor-pointer py-1"
            @click="
              this.$router.push({
                name: 'PrivacyNotice',
              })
            "
          >
            Privacy Notice
          </h4>
          <h4
            :class="[
              $route.path.includes('terms')
                ? 'text-magenta'
                : 'text-grey-mid dark:text-dark-textPrimary',
            ]"
            class="font-medium cursor-pointer py-1"
            @click="
              this.$router.push({
                name: 'TermsOfUse',
              })
            "
          >
            Terms of Use
          </h4>
        </div>

        <div class="flex content-center py-4 px-6">
          <div
            :class="{ 'bg-magenta': theme === 'dark' }"
            class="
              w-10
              h-5
              flex
              items-center
              bg-grey-mid
              rounded-full
              py-1
              pl-6px
              mt-2px
              cursor-pointer
              duration-300
              ease-in-out
            "
            @click="toggleTheme()"
          >
            <div
              :class="{ 'translate-x-4': theme === 'dark' }"
              class="
                bg-white
                w-3
                h-3
                rounded-full
                shadow-md
                transform
                duration-300
                ease-in-out
              "
            ></div>
          </div>

          <h4 class="text-grey-mid dark:text-dark-textPrimary font-medium ml-5">
            Dark Mode
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";

import { logout } from "@Libs/hooks/useAuth";
import { useSidebar } from "@Libs/hooks/useSidebar";

import HomeIcon from "@Assets/images/HomeIcon";
import EventIcon from "@Assets/images/EventIcon";
import UsersIcon from "@Assets/images/UsersIcon";
import LogoutIcon from "@Assets/images/LogoutIcon";
import PasswordReset from "@Assets/images/PasswordReset";

export default {
  name: "Sidebar",
  components: {
    HomeIcon,
    EventIcon,
    UsersIcon,
    LogoutIcon,
    PasswordReset,
  },
  setup() {
    const { isOpen } = useSidebar();
    const activeClass = ref(
      "active text-base text-magenta uppercase font-bold",
    );
    const inactiveClass = ref(
      "text-base text-grey-mid dark:text-dark-textPrimary uppercase font-bold hover:text-magenta dark:hover:text-magenta",
    );

    return { isOpen, activeClass, inactiveClass };
  },
  computed: {
    ...mapGetters({ theme: "getTheme", isAdmin: "isAdmin" }),
  },
  methods: {
    toggleTheme() {
      this.$store.dispatch("toggleTheme");
    },
    async handleLogout() {
      await logout();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="postcss">
.nav-link:hover svg,
.nav-link.active svg {
  @apply text-magenta;
}

.nav-link.logout:hover svg {
  @apply stroke-magenta;
}
</style>
