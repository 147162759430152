<template>
  <section class="min-h-screen flex justify-center items-center bg-login bg-cover pb-10 px-4 sm:px-6 lg:px-8">
    <div ref="welcomeText" class="w-full">
      <div class="w-34rem flex flex-col bg-white rounded-xl md:rounded-3xl shadow-xl ml-auto mr-auto md:p-10 p-6">
        <div class="flex items-center">
          <img src="@/assets/images/logo.png" alt="Givergy logo" class="w-1/2 object-cover" />
        </div>

        <div class="text-xl text-blue-light font-montserrat font-semibold mt-4 md:mt-10">
          <p>Raising more.</p>
        </div>
      </div>
    </div>

    <div ref="welcomeButtons" class="w-full absolute bottom-0 flex justify-between pb-10 px-4 sm:px-6 lg:px-8 flex-col-reverse items-center md:flex-row">
      <div class="flex items-center bg-white rounded-3xl py-3 px-5">
        <span class="text-grey-mid font-semibold">Powered by:</span>
        <img src="@/assets/images/logo.png" alt="Givergy Logo" class="w-28 object-cover ml-3" />
      </div>
      <button class="flex items-center bg-white rounded-3xl focus:outline-none py-3 px-5 mb-2 md:mb-0">
        <span class="text-blue-light font-bold">Need Some Help?</span>

        <QuestionIcon class="w-7 h-7 text-blue-light ml-2" />
      </button>
    </div>

    <div ref="loginForm" class="w-full hidden justify-center opacity-0">
      <div class="w-90% md:w-28rem flex flex-col bg-white rounded-3xl shadow-xl p-7 md:p-10">
        <img src="@/assets/images/logo.png" alt="Your avatar" class="w-1/2 object-cover mx-auto my-5 md:my-11 lg:my-0 lg:ml-3 lg:mr-0" />
        <h1 class="hidden lg:block h1-Alternative mt-8 ml-3">
          {{ isResetPassword ? "Reset password" : "Welcome to the admin site" }}
        </h1>
        <h2 v-if="!isResetPassword" class="hidden lg:block h2-Alternative text-grey-dark mt-4 ml-3">Please login below.</h2>

        <Form v-if="!isResetPassword" v-slot="{ errors }" :validation-schema="schema" @submit="onSubmit">
          <div class="relative mt-5 md:mt-8">
            <span :class="{ '-top-6': errors.userName }" class="absolute inset-y-0 left-0 pl-5 md:pl-6 flex items-center">
              <UserIcon class="h-7 w-7 md:h-8 md:w-8 text-grey-dark" />
            </span>

            <Field
              id="user-name"
              v-model="userName"
              name="userName"
              type="text"
              placeholder="Username"
              :class="{
                'text-danger border border-danger': errors.userName,
              }"
              class="form-input w-full text-lg text-black-1 rounded placeholder-grey-mid border border-grey-mid focus:ring-magenta focus:border-magenta pl-16 md:pl-16.5 py-3 md:py-4"
            />
            <p class="text-danger dark:text-danger text-base italic">
              {{ errors.userName }}
            </p>
          </div>

          <div class="relative mt-5 md:mt-8">
            <span :class="{ '-top-6': errors.password }" class="absolute inset-y-0 left-0 pl-5 md:pl-6 flex items-center">
              <LockIcon class="h-7 w-7 md:h-8 md:w-8 text-grey-dark" />
            </span>

            <Field
              id="password"
              v-model="password"
              name="password"
              type="password"
              placeholder="Password"
              :class="{
                'text-danger border border-danger': errors.password,
              }"
              class="form-input w-full text-lg text-black-1 rounded placeholder-grey-mid border border-grey-mid focus:ring-magenta focus:border-magenta pl-16 md:pl-16.5 py-3 md:py-4"
            />
            <p class="text-danger dark:text-danger text-base italic">
              {{ errors.password }}
            </p>
          </div>

          <button type="submit" class="button_large-Alternative w-full uppercase bg-blue-light border rounded-full focus:outline-none px-8 py-4 md:py-5 mt-8 mb-7 md:mb-11 lg:mb-0">Login</button>
        </Form>

        <Form v-if="isResetPassword" v-slot="{ errors }" :validation-schema="resetPasswordSchema" @submit="onResetPassword">
          <div class="relative mt-5 md:mt-8">
            <span :class="{ '-top-6': errors.newPassword }" class="absolute inset-y-0 left-0 pl-5 md:pl-6 flex items-center">
              <LockIcon class="h-7 w-7 md:h-8 md:w-8 text-grey-dark" />
            </span>

            <Field
              id="new-password"
              v-model="newPassword"
              name="newPassword"
              type="password"
              placeholder="New password"
              :class="{
                'text-danger border border-danger': errors.newPassword,
              }"
              class="form-input w-full text-lg text-black-1 rounded placeholder-grey-mid border border-grey-mid focus:ring-magenta focus:border-magenta pl-16 md:pl-16.5 py-3 md:py-4"
            />
            <p class="text-danger dark:text-danger text-base italic">
              {{ errors.newPassword }}
            </p>
          </div>

          <div class="relative mt-5 md:mt-8">
            <span :class="{ '-top-6': errors.passwordConfirmation }" class="absolute inset-y-0 left-0 pl-5 md:pl-6 flex items-center">
              <LockIcon class="h-7 w-7 md:h-8 md:w-8 text-grey-dark" />
            </span>

            <Field
              id="password-confirmation"
              v-model="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              placeholder="Password confirmation"
              :class="{
                'text-danger border border-danger': errors.passwordConfirmation,
              }"
              class="form-input w-full text-lg text-black-1 rounded placeholder-grey-mid border border-grey-mid focus:ring-magenta focus:border-magenta pl-16 md:pl-16.5 py-3 md:py-4"
            />
            <p class="text-danger dark:text-danger text-base italic">
              {{ errors.passwordConfirmation }}
            </p>
          </div>

          <button type="submit" class="button_large-Alternative w-full uppercase bg-blue-light border rounded-full focus:outline-none px-8 py-4 md:py-5 mt-8 mb-7 md:mb-11 lg:mb-0">
            Reset Password
          </button>
        </Form>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { TweenMax } from "gsap";

import { login, resetPassword } from "@Libs/hooks/useAuth";
import { sleep } from "@Helpers/sleep";

import QuestionIcon from "@Assets/images/QuestionIcon";
import UserIcon from "@Assets/images/UserIcon";
import LockIcon from "@Assets/images/LockIcon";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    QuestionIcon,
    UserIcon,
    LockIcon,
  },
  setup() {
    const welcomeText = ref(null);
    const welcomeButtons = ref(null);
    const loginForm = ref(null);

    const schema = Yup.object().shape({
      userName: Yup.string().required("User name is required"),
      password: Yup.string().required("Password is required"),
    });
    const resetPasswordSchema = Yup.object().shape({
      newPassword: Yup.string().required("New password is required"),
      passwordConfirmation: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });

    return {
      welcomeText,
      welcomeButtons,
      loginForm,
      schema,
      resetPasswordSchema,
    };
  },
  data() {
    return {
      userName: "",
      password: "",
      isResetPassword: false,
      newPassword: "",
      passwordConfirmation: "",
      resetPasswordData: null,
    };
  },
  async created() {
    this.$store.dispatch("closeLoading");
    await sleep(2000);

    TweenMax.to([this.welcomeText, this.welcomeButtons], {
      scale: 0.95,
      duration: 0.5,
      ease: "ease-out",
      display: "none",
      opacity: 0,
    });

    await sleep(500);

    TweenMax.to(this.loginForm, {
      scale: 1,
      duration: 0.5,
      ease: "ease-in",
      display: "flex",
      opacity: 1,
    });
    this.$store.dispatch("closeLoading");
  },
  methods: {
    async onSubmit() {
      // Show loading
      this.$store.dispatch("toggleLoading");

      const result = await login(this.userName, this.password);

      await sleep(1600);

      // Hide Loading
      this.$store.dispatch("closeLoading");

      if (!result.status && result.message === "You need to change your password for first time logging in") {
        this.isResetPassword = true;
        this.resetPasswordData = result.data;
      }

      if (result.status) {
        const { fullName, email, id, accessLevel } = result.data;
        this.$store.dispatch("saveUserInfo", {
          username: fullName,
          email,
          id,
          role: accessLevel,
        });
        this.$router.push("home");
      } else {
        this.$store.dispatch("deleteUserInfo");
      }

      // Show Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: true,
        status: result.status,
        title: result.status ? "Successfully!" : "Failed!",
        message: result.message,
      });

      await sleep(3000);

      // Hide Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: false,
      });
    },
    async onResetPassword() {
      // Show loading
      this.$store.dispatch("toggleLoading");

      const result = await resetPassword(this.resetPasswordData, this.newPassword);

      await sleep(1600);

      // Hide Loading
      this.$store.dispatch("closeLoading");

      if (result.status) {
        const { fullName, email, id, accessLevel } = result.data;
        this.$store.dispatch("saveUserInfo", {
          username: fullName,
          email,
          id,
          role: accessLevel,
        });
        this.$router.push("home");
      } else {
        this.$store.dispatch("deleteUserInfo");
      }

      // Show Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: true,
        status: result.status,
        title: result.status ? "Successfully!" : "Failed!",
        message: result.message,
      });

      await sleep(3000);

      // Hide Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: false,
      });
    },
  },
};
</script>
 <style lang="css">
.w-34rem {
  max-width: 90vw;
}
</style>
