<template>
  <section class="bg-grey-light font-sans">
    <div class="container w-full md:max-w-3xl mx-auto py-20">
      <div
        class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal"
        style="font-family: Georgia, serif"
      >
        <div class="font-sans">
          <h1
            class="font-bold font-sans break-normal text-black-1 pt-6 pb-2 text-3xl md:text-4xl"
          >
            PRIVACY NOTICE
          </h1>
        </div>
        <p class="py-6 leading-1.5 tracking-normal">
          At Website Name, accessible at Website.com, one of our main priorities
          is the privacy of our visitors. This Privacy Policy document contains
          types of information that is collected and recorded by Website Name
          and how we use it. If you have additional questions or require more
          information about our Privacy Policy, do not hesitate to contact us
          through email at Email@Website.com This privacy policy applies only to
          our online activities and is valid for visitors to our website with
          regards to the information that they shared and/or collect in Website
          Name. This policy is not applicable to any information collected
          offline or via channels other than this website.
        </p>

        <ol>
          <li class="py-3 leading-1.5 tracking-normal">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </li>
          <li class="py-3 leading-1.5 tracking-normal">
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </li>
          <li class="py-3 leading-1.5 tracking-normal">
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyNotice",
};
</script>
